import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { TermsAndConditionsContent } from '../components/terms-and-conditions-content';

const TermsAndConditionsPage = () => (
    <Layout>
        <SEO title="Terms & Conditions" />
        <div class="container" style={{ textAlign: 'justify' }}>
            <TermsAndConditionsContent />
        </div>
    </Layout >
)

export default TermsAndConditionsPage
