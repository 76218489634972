import React from "react"
import "./content.css"

export class TermsAndConditionsContent extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  setPageHeading = () => {
    return (
      <>
        <div className="terms-header-div">
          <h1>Tiggbee Terms of Service</h1>
          <div>Effective as of 03/14/2023</div>
        </div>
      </>
    )
  }

  setContent = () => {
    return (
      <>
        <p>
          These terms of service (“<strong>Terms</strong>”) are a legally
          binding contract between you and TIGGBEE LLC, a Delaware limited
          liability company (“<strong>Tiggbee</strong>,” “<strong>we</strong>,”
          “<strong>us</strong>,” or “<strong>our</strong>”), that you are
          agreeing to by registering for a Tiggbee account, by participating in
          any events, or by accessing or using our website and/or our education
          and video platform (including any messaging, attachments, materials,
          or other functionality of any kind) or any part of it (the
          “Services”). If you do not agree with these Terms, do not register, or
          use any of the Services.
        </p>
        <p>
          If you are using the Services on behalf of an organization (for
          example, you are an account administrator), you are agreeing to these
          Terms for that organization and are promising to us that you have the
          authority to bind that organization to these Terms (in which event, “
          <strong>you</strong>” and “<strong>your</strong>” will refer to that
          organization).
        </p>
        <br />
        <h2>1. Registration and Accounts</h2>
        <p>
          If your account requires registration, you must complete the
          registration process by providing complete and accurate information.
          For non-SSO (single sign on) accounts, you must also provide and
          maintain one dedicated email address for communications with Tiggbee.
        </p>
        <p>
          The Services have different levels of access and permissions for
          various users, including presenters (e.g., businesses and content
          creators), admins (e.g., teachers), and viewers (e.g., students). We
          may change such user roles at any time. If you are an account
          administrator, “<strong>your users</strong>” includes all of your
          presenters, admins, and viewers.
        </p>
        <p>
          You are solely responsible for all files and information that you or
          your users upload to or share through Tiggbee and will ensure that
          your users comply with your obligations under these Terms. We may
          impose reasonable limits on uses of the Services to prevent
          degradation of the performance of the Services for you or other users.
        </p>
        <p>
          Only you may use your account and are responsible for your account. If
          you become aware of any unauthorized use of the Services or your
          account, please contact us immediately at{" "}
          <a href="mailto:support@tiggbee.com" rel="noreferrer">
            support@tiggbee.com
          </a>
          . In addition, you are solely responsible to ensure that your
          hardware, network, Internet connection, and other equipment meet the
          requirements to use the Services.
        </p>
        <br />
        <h2>2. Changes to These Terms</h2>
        <p>
          We reserve the right to modify these Terms at any time. We will post
          the most current version of these Terms on our website. Any revisions
          to these Terms will be effective immediately upon posting by us. For
          any material changes to these Terms, we will take reasonable steps to
          notify you of such changes, via a banner on the website, email
          notification, another method, or combination of methods. In all cases,
          your continued use of the Services after publication of such changes,
          with or without notification, constitutes binding acceptance of the
          revised Terms.
        </p>
        <br />
        <h2>3. Changes to the Services</h2>
        <p>
          We are constantly changing and improving the Services. We may deploy
          changes, updates, or enhancements to the Services at any time. We may
          also add or remove functionalities or features, or we may suspend or
          stop the Services altogether. Accordingly, either you or Tiggbee may
          terminate your use of any Service for any reason at any time. For
          scheduled downtime to the Services, we will attempt to notify you in
          advance via the Services, by email to your primary email address,
          and/or by other method or combination of methods.
        </p>
        <br />
        <h2>4. Suspension or Termination of Your Account</h2>
        <p>
          We may suspend or terminate your account for any violation of these
          Terms, for any activities prohibited by these Terms, for circumstances
          beyond our control, or if required by law. If we do so, then your
          access to and use of the Services will be suspended or terminated
          immediately.
        </p>
        <br />
        <h2>5. Customer Support</h2>
        <p>
          Customer support is available by phone, email, and through the
          Services. Please see the contact or about us page for more details.
          Customers may use these support resources to ask questions and to
          report errors or problems with the Services.
        </p>
        <br />
        <h2>6. Consent to Electronic Communications and Solicitation</h2>
        <p>
          You agree that we may send you (including via email) information
          regarding the Services, such as notices about your use of the
          Services, updates to the Services and new features or offerings, and
          promotional information and materials. Notices emailed to you will be
          considered given and received when the email is sent.
        </p>
        <br />
        <h2>7. Tiggbee Proprietary Rights</h2>
        <p>
          You are not being granted any rights whatsoever in any intellectual
          property or proprietary rights of Tiggbee or its licensors except for
          a limited, nonexclusive, nontransferable, non-assignable and revocable
          license to use the Services solely for your authorized use as
          described in and subject to these Terms. All right, title, and
          interest, including all patents, copyrights, trade secrets, and any
          other intellectual property or proprietary rights, in and to the
          Services are owned solely by Tiggbee and its licensors. This includes
          all know-how, technology, and other intellectual property or
          proprietary information developed during the provision of the
          Services. No title to or ownership of any intellectual property or
          proprietary rights related to the Services is transferred to you or
          anyone else pursuant to these Terms.
        </p>
        <p>
          If you or your users provide comments, suggestions, or recommendations
          to us about the Services (“<strong>Feedback</strong>”), you and your
          users are automatically assigning any such Feedback to us.
        </p>
        <br />
        <h2>8. Restrictions on Use</h2>
        <p>
          You agree not to decompile, reverse engineer, or undertake any similar
          efforts with respect to the Services. You will not create any
          derivative works of the Services. You will not access or use the
          Services to develop your own software or services, benchmark the
          Services, frame, or mirror the Services, or copy or modify of any part
          of the Services.
        </p>
        <p>
          Moreover, you agree that you will not, and that you will not you
          encourage others or assist others to, harm the Services or use the
          Services to harm others. For example, you must not use the Services to
          harm, threaten, or harass another person, organization, or Tiggbee.
          You must not: (a) damage, disable, overburden, or impair the Services
          (or any connected network); (b) resell or redistribute the Services;
          (c) use any unauthorized means to modify, reroute, or gain access to
          the Services or any part of the Services; (d) use any automated method
          to access or use the Services; (e) use the Services to distribute
          unsolicited promotional or commercial content or other unwanted
          solicitations; (f) cause or encourage any inaccurate measurements of
          genuine user engagement with the Services; (g) misuse any reporting,
          flagging, complaint, dispute, or appeals process, including by making
          groundless, vexatious, or frivolous submissions; (h) sell, advertise,
          sponsor, or promote anything without Tiggbee’s prior written consent
          and a separate written agreement with Tiggbee with respect to such
          activities; or (i) use the Services to violate any law or anyone’s
          rights or to distribute malware or malicious content.
        </p>
        <p>
          You are solely responsible for compliance with applicable laws and
          regulations related to your and your users’ use of the Services.
        </p>
        <br />
        <h2>9. Your Content</h2>
        <p>
          All digital files (including videos, messages, live presentations,
          attachments, materials, or other content) that you or your users
          upload to or share through the Services (“Your Content”) is yours. You
          are solely responsible for Your Content. This includes making sure
          that you have all the rights you need to Your Content and that Your
          Content does not violate any law or these Terms or the Tiggbee
          Community.
        </p>
        <p>
          All Tiggbee content must be appropriate for minors and comply with the
          Federal Trade Commission’s Children’s Online Privacy Protection Rule
          (COPPA) guidelines. Learn more{" "}
          <a
            href="https://www.ftc.gov/legal-library/browse/rules/childrens-online-privacy-protection-rule-coppa"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>
          . Content must meet a Motion Picture Association of America rating of
          “G” – General audiences. All ages admitted. Learn more here. Also, be
          advised that we work closely with law enforcement and we report child
          endangerment.
        </p>
        <p>
          By uploading or sharing Your Content to the Services, you grant to
          Tiggbee a worldwide, exclusive (with respect to any of Your Content
          that comprises a local or national event), perpetual, irrevocable,
          royalty-free, sublicensable and transferable license to use Your
          Content (including to reproduce, distribute, prepare derivative works,
          display, and perform it) in connection with the Services and Tiggbee’s
          (and its successors’ and affiliates’) business, including for the
          purpose of promoting and redistributing part or all of the Services.
          This also includes the right to aggregate anonymous data from Your
          Content regarding use and functioning of the Services by you and other
          users, and such aggregated data will be our property.
        </p>
        <p>
          You also grant each other user of the Services a worldwide,
          nonexclusive, royalty-free license to access Your Content through the
          Services, and to use Your Content, including to reproduce, distribute,
          prepare derivative works, display, and perform it, only as enabled by
          a feature of the Services. For clarity, this license does not grant
          any rights or permissions for a user to make use of Your Content
          independent of the Services.
        </p>
        <p>
          If we reasonably believe that any of Your Content is in breach of
          these Terms or may cause harm to Tiggbee, our users, or third parties,
          we may remove or take down all or any part of Your Content in our
          discretion.
        </p>
        <p>
          We respect the intellectual property of others and will respond to
          notices of alleged copyright infringement that comply with the law. We
          reserve the right to delete or disable Your Content alleged to violate
          copyright laws or this Agreement and reserve the right to terminate
          the account(s) of violators.
        </p>
        <p>
          If you believe there has been a violation of your intellectual
          property rights, please contact our designated copyright agent by
          email at <a href="mailto:support@tiggbee.com">support@tiggbee.com</a>.
        </p>
        <h2>10. Third-Party Content and Integrations</h2>
        <p>
          Through the Services, you will have the ability to access and/or use
          content provided by presenters, admins, other users, and/or other
          third parties and links to websites and services maintained by third
          parties (“<strong>Third-Party Content</strong>”). Tiggbee cannot
          guarantee that such ThirdParty Content, in the Services or elsewhere,
          will be free of material you may find objectionable or otherwise
          inappropriate or of malware or other contaminants that may harm your
          computer, mobile device, or any files therein. Tiggbee makes no
          warranties of any kind and assumes no liability of any kind related to
          your access or use of, or inability to access and use, any Third-Party
          Content.
        </p>
        <p>
          We may make available third-party applications, products, or services
          for use in connection with the Services (“
          <strong>Third-Party Integration</strong>”). Your use of any
          Third-Party Integration and rights with respect to such Third-Party
          Integration are solely between you and the applicable third party.
          Tiggbee makes no warranties of any kind and assumes no liability of
          any kind related to your access or use of, or inability to access and
          use, any Third-Party Integration. If you have any questions or
          concerns regarding any Third-Party Integration, please contact the
          applicable third party. In addition, the Services may contain links to
          third-party websites and online services that are not owned or
          controlled by Tiggbee. Tiggbee has no control over, and assumes no
          responsibility for, such websites and online services. Be aware when
          you leave the Services, we suggest you read the terms and privacy
          policy of each third-party website and online service that you visit.
        </p>
        <h2>11. Disclaimers</h2>
        <p>
          TO THE EXTENT NOT PROHIBITED BY LAW, TIGGBEE (A) PROVIDES THE SERVICES
          "AS IS", "WITH ALL FAULTS" AND "AS AVAILABLE"; (B) MAKES NO
          REPRESENTATIONS OR WARRANTIES OR CONDITIONS WHETHER EXPRESS OR IMPLIED
          (E.G., WARRANTY OF MERCHANTABILITY, SATISFACTORY QUALITY, FITNESS FOR
          A PARTICULAR PURPOSE, OR NONINFRINGEMENT); AND (C) DOES NOT GUARANTEE
          THAT THE SERVICES WILL BE UNINTERRUPTED, ERROR-FREE OR FREE OF HARMFUL
          COMPONENTS, THAT YOUR CONTENT WILL BE SECURE OR NOT OTHERWISE LOST OR
          DAMAGED.
        </p>
        <p>
          IF YOU ARE DISSATISFIED WITH ANY PORTION OF THE SERVICES OR WITH THESE
          TERMS, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USE OF THE
          SERVICES.
        </p>
        <h2>12. Indemnification</h2>
        <p>
          To the extent not prohibited by law, you agree to defend, indemnify,
          and hold harmless Tiggbee, its affiliates, officers, directors,
          employees and agents (“<strong>Tiggbee Parties</strong>”), from and
          against any and all claims, damages, obligations, losses, liabilities,
          costs or debt, and expenses (including but not limited to attorney's
          fees) arising from: (a) your use of and access to the Services; (b)
          your violation of any of these Terms; (c) your violation of any
          third-party right, including without limitation any copyright,
          property, or privacy right; or (d) any claim that Your Content caused
          damage to a third party. This defense and indemnification obligation
          will survive any termination of these Terms or your use of the
          Services.
        </p>
        <h2>13. Limitation of Liability</h2>
        <p>
          TO THE EXTENT NOT PROHIBITED BY LAW, IN NO EVENT WILL THE TIGGBEE
          PARTIES, OR ANY OF THEM, BE LIABLE FOR ANY INDIRECT, INCIDENTAL,
          SPECIAL, PUNITIVE, COVER OR CONSEQUENTIAL DAMAGES (INCLUDING, WITHOUT
          LIMITATION, DAMAGES FOR LOST PROFITS, REVENUE, GOODWILL, USE OR
          CONTENT) HOWEVER CAUSED, UNDER ANY THEORY OF LIABILITY, INCLUDING,
          WITHOUT LIMITATION, CONTRACT, TORT, WARRANTY, NEGLIGENCE OR OTHERWISE,
          EVEN IF SUCH ENTITY OR PERSON HAS BEEN ADVISED AS TO THE POSSIBILITY
          OF SUCH DAMAGES.
        </p>
        <p>
          THE AGGREGATE LIABILITY OF THE TIGGBEE PARTIES RELATING TO THE
          SERVICES WILL BE LIMITED TO TWENTY U.S. DOLLARS ($20) OR THE TOTAL
          AMOUNT OF FEES RECEIVED BY TIGGBEE FROM YOU FOR THE USE OF PAID
          SERVICES DURING THE PAST SIX MONTHS, WHICHEVER IS GREATER. THE
          LIMITATIONS AND EXCLUSIONS ALSO APPLY IF THIS REMEDY DOES NOT FULLY
          COMPENSATE YOU FOR ANY LOSSES OR FAILS OF ITS ESSENTIAL PURPOSE.
        </p>
        <p>
          SOME JURISDICTIONS DO NOT ALLOW CERTAIN LIMITATIONS OF LIABILITY, IN
          WHICH CASE SUCH LIMITATION WILL NOT APPLY TO YOU TO THE EXTENT
          PROHIBITED BY LAW.
        </p>
        <h2>14. Your Name and Logo</h2>
        <p>
          As part of the Services and in connection with the marketing,
          promotion, and display of the Services, we may reference you
          (including use of your branding) or Your Content. If you object to any
          such use, you will notify us, and we will not use your name or
          branding or will remove or alter your name or branding in any such
          materials as requested.
        </p>
        <h2>15. Privacy Policy</h2>
        <p>
          Your privacy is important to us. Please carefully review our privacy
          policy at{" "}
          <a
            href="https://tiggbee.com/privacy-policy-page/"
            target="_blank"
            rel="noreferrer"
          >
            https://tiggbee.com/privacy-policy-page
          </a>
          , which is incorporated by reference into these Terms. Our privacy
          policy details the way we collect, use, and disclose information about
          you.
        </p>
        <h2>16. General Terms</h2>
        <h3>16.1 Assignment</h3>
        <p>
          We may assign, transfer, or otherwise dispose our rights and
          obligations under these Terms, in whole or in part, at any time
          without notice. You may not assign these Terms or transfer any rights
          to use the Services, unless we allow you to do so in writing.
        </p>
        <h3>16.2 Waiver</h3>
        <p>
          The failure of either of us to insist upon or enforce strict
          performance of any of the provisions of these Terms or to exercise any
          of our respective rights or remedies will not be construed as a waiver
          or relinquishment to any extent of such right to assert or rely upon
          any such provision, right, or remedy in that or any other instance,
          all of which will remain in full force and effect.
        </p>
        <h3>16.3 Entire Agreement; Severability</h3>
        <p>
          These Terms apply to the maximum extent permitted by law. If any
          government authority holds that we cannot enforce a part of these
          Terms as written, you and we will replace such part with similar terms
          to the extent enforceable under the relevant law, but the rest of
          these Terms will remain in effect. This is the entire agreement
          between you and us regarding its subject matter and supersedes any
          oral and written understandings, communications, or agreements
          regarding your use of the Services or other matters described in these
          Terms.
        </p>
        <h3>16.4 Governing Law; Jurisdiction</h3>
        <p>
          You agree that these Terms, and your relationship with Tiggbee will be
          governed by the laws of the State of California regardless of
          conflict-of-laws principles. However, some jurisdictions have laws
          that require agreements to be governed by the local laws of such
          jurisdiction for such users. This paragraph does not override those
          laws. We both agree that the United Nations Convention on Contracts
          for the International Sale of Goods and the Uniform Computer
          Information Transactions Act do not apply to these Terms. We both
          agree that all of these claims can only be litigated in the federal or
          state courts in Orange County, California, and we each agree to
          exclusive personal jurisdiction in those courts. However, you agree
          that Tiggbee can apply for injunctive remedies in any jurisdiction. We
          both agree that the prevailing party in any action to enforce these
          Terms will be entitled to recover costs and expenses including,
          without limitation, reasonable attorneys’ fees and other legal costs.
        </p>
        <h3>16.5 Relationship</h3>
        <p>
          Tiggbee and you are not legal or joint venture partners or agents. We
          are independent contractors. These Terms are solely for your and our
          benefit. It is not for the benefit of any other person, except for
          permitted assignees and successors.
        </p>
        <h3>16.6 Notice to California Users</h3>
        <p>
          Under California Civil Code Section 1789.3, California website users
          are entitled to the following specific consumer rights notice: The
          Complaint Assistance Unit of the Division of Consumer Services of the
          California Department of Consumer Affairs may be contacted in writing
          at 1625 N. Market Blvd., Suite S-202, Sacramento, California 95834, or
          by telephone at (800) 952-5210
        </p>
        <h3>16.7 U.S. Government Restricted Rights</h3>
        <p>
          If you, or any of your users, is a U.S. government entity or if these
          Terms otherwise become subject to the Federal Acquisition Regulations
          (FAR), you acknowledge that elements of the Services constitute
          software and documentation and are provided as “Commercial Items” as
          defined at 48 C.F.R. 2.101, and are being licensed to U.S. government
          user as commercial computer software and commercial computer software
          documentation subject to the restricted rights described in 48 C.F.R.
          2.101 and 12.212. Consistent with 48 C.F.R. 12.212 and 48 C.F.R.
          227.7202-1 through 227.7202-4, all U.S. government end users acquire
          such software and documentation with only those rights set forth
          herein.
        </p>
      </>
    )
  }

  render() {
    const html = (
      <>
        <div className="terms-container-div">
          <br /> <br /> <br />
          {this.setPageHeading()}
          <br />
          <br />
          {this.setContent()}
          <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br />{" "}
          <br /> <br />
        </div>
      </>
    )
    return html
  }
}
